/* CSS Micro Reset */

* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

th,
td {
  text-align: left;
  vertical-align: top;
}

img,
iframe {
  border: 0;
}

body{
  background-color: darkgray;
}