@import url("https://fonts.googleapis.com/css2?family=Lobster&display=swap");
.container {
  width: 80%;
  max-width: 640px;
  background-color: rgb(7, 33, 59);
  padding: 1rem 3rem;
  border-radius: 0.5rem;
  color: #fff;
  position: relative;
}
.generate_button {
  appearance: none;
  outline: none;
  border: none;
  font-size: 1rem;
  color: white;
  cursor: pointer;
  width: 50px;
  height: 50px;
  text-align: center;
  background-color: palevioletred;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 50%;
  transform: translate(50%, -50%);
}
.generate_button:hover {
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}
.generate_button:hover i {
  transition: 0.3s ease-in-out;
  transform: rotate(360deg);
  transform-origin: center;
}
.x {
  outline: 2px solid red;
}
.quote {
  font-family: "Lobster", cursive;
  font-size: 2.5rem;
  position: relative;
  z-index: 1;
}
.quote::before {
  content: "\f10d";
  font-size: 4rem;
  position: absolute;
  color: yellow;
  top: -1rem;
  opacity: .7;
  left: 0;
  z-index: -1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  background: linear-gradient(45deg, red, yellow);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.author {
  font-family: "Roboto", sans-serif;
  text-align: right;
  font-size: 1.4rem;
  color: yellow;
}

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
